
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Link from 'next/link';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import Loader from "../components/common/Loader";
import BuyButton from "../components/common/buttons/BuyButton";
export default function FourOhFour({ title, description, canonical }) {
    const { t } = useTranslation();
    const seo = t('common:seo', {}, { returnObjects: true });
    const not_found = t('common:not_found', {}, { returnObjects: true });
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 2000, true);
        });
        Promise.all([appLoading]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    return (<MainLayout t={t} loaded={loaded} title={seo.not_found.title} alternate={''}>

            <Header t={t}/>
            <section className='not_found'>
                <div className='container'>
                        <div className="not_found_content">
                            <h2 className='not_found_content_title'>404</h2>
                            <p className='not_found_content_subtitle'>Oops! Looks like you found a broken link. Head back to the homepage and try again.</p>

                            <Link className='home_button' href='/'>
                                <BuyButton text={'Back To Home'}/>
                            </Link>
                        </div>
                    </div>
            </section>
            <Footer t={t}/>
            <Loader isLoading={isLoading} loaded={loaded}/>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__192c2862797__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192c2862797__ as getStaticProps }
  